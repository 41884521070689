
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import FilterOnFields from '@/components/common/FilterOnFields.vue';
import PaginationPageSizeControl from '@/components/common/PaginationPageSizeControl.vue';
import { humanizeString } from '@/common/utilities';

@Component({ components: { FilterOnFields, PaginationPageSizeControl } })
export default class DataTable extends Vue {
  @Prop({ default: false }) private small: boolean;
  @Prop() private items: any[];
  @Prop() private fields: any[];
  @Prop() private initialSort: string;
  @Prop({ default: false }) private sortDesc: boolean;
  @Prop({ default: 10 }) private pageSize: number;
  @Prop({ default: '625px' }) private stickyHeaderSize: string;
  @Prop({ default: true, type: Boolean }) private showEmpty: boolean;
  @Prop({ default: true, type: Boolean }) private showFilter: boolean;
  @Prop({ default: true, type: Boolean }) private showPagination: boolean;
  @Prop({ default: false, type: Boolean }) private isFetchingAdditionalRows: boolean;
  @Prop({ default: false, type: Boolean }) private showHeaderFilter: boolean;
  @Prop({ default: true, type: Boolean }) private ignoreFilterCasing: boolean;
  @Prop({ default: true, type: Boolean }) private headerFilterPlaceholderText: boolean;
  filter: string = null;
  currentPage = 1;
  totalRows = 1;
  sortBy: string = '';
  filterOnFields: any[] = [];
  currentPageSize = 10;
  filters: any = {
    id: '',
    issuedBy: '',
    issuedTo: '',
  };

  mounted() {
    this.currentPageSize = this.pageSize;
  }

  @Watch('items', { immediate: true })
  private onItemsChange(newValue: any[], oldValue: any[]) {
    this.totalRows = !!newValue ? newValue.length : 0;
    this.sortBy = this.initialSort;
  }

  private onFiltered(filteredItems: any[]) {
    this.totalRows = filteredItems.length;
    if (this.currentPage > this.maxPageSize) {
      this.currentPage = 1;
    }
  }

  get filterValue() {
    return this.filter;
  }

  get maxPageSize() {
    if (this.items) {
      return this.items.length;
    }
  }

  get showGrid() {
    return this.showEmpty ? true : this.items.length > 0;
  }

  private filterOnFieldsHasChanged(filterOnFields: string[]) {
    this.filterOnFields = filterOnFields;
  }

  pageToFirst(): void {
    this.currentPage = 1;
  }

  filtered() {
    if (!this.items || this.items.length === 0) {
      return [];
    }

    if (!this.showHeaderFilter) {
      return this.items;
    }

    const filtered = this.items.filter((item) => {
      return Object.keys(this.filters).every((key) => {
        const fieldKey = this.getFieldKey(key);
        let itemValue = item[fieldKey];
        const filterValue = String(this.filters[fieldKey]);

        // Handle arrays and objects
        if (Array.isArray(itemValue)) {
          itemValue = itemValue.map(val => (typeof val === 'object' && val !== null) ? JSON.stringify(val) : String(val)).join(', ');
        } else if (typeof itemValue === 'object' && itemValue !== null) {
          itemValue = JSON.stringify(itemValue);
        } else {
          itemValue = String(itemValue);
        }

        // Handle case sensitivity
        if (this.ignoreFilterCasing) {
          return itemValue.toLowerCase().includes(filterValue.toLowerCase());
        } else {
          return itemValue.includes(filterValue);
        }
      });
    });

    if (filtered.length === 0) {
      const emptyItem = Object.keys(this.items[0]).reduce((obj: any, value: any) => {
        obj[value] = '';
        return obj;
      }, {});
      return [emptyItem];
    }

    this.onFiltered(filtered);
    return filtered;
  }

  getFieldKey(field: any): string {
    return typeof field === 'string' ? field : field.key;
  }

  getFieldLabel(field: any): string {
    if (!this.headerFilterPlaceholderText) {
      return '';
    }

    return typeof field === 'string' ? humanizeString(field) : field.label;
  }

  @Watch('pageSize', { immediate: true })
  private updatePageSize(newValue: any, oldValue: any) {
    this.pageSizeChanged(newValue);
  }

  private pageSizeChanged(pageSize: any) {
    this.currentPageSize = pageSize;
  }
}
